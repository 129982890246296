import { gql } from '@apollo/client';

export const useRelatedVehicles = gql`
  query RelatedVehicles {
    relatedVehicles {
      objectID
      make
      hasReservePrice
      modelYear
      lowEstimate
      highEstimate
      salePrice
      auctionType
      auctionName
      lotNumber
      salesForceId
      images {
        file {
          url
          details {
            image {
              width
              height
            }
          }
        }
        title
      }
      model
      auctionEndDate
      upcomingAuctionEndDate
      auctionStartDate
      slug
      privateSalesPrice
      itemType
      title
      venueId
    }
  }
`;
