import React from 'react';
import _ from 'lodash';
import {
  getAuctionYear,
  getContentfulItemTitle,
  getPriceFormatted,
  getCurrencyCode,
  getEstimates,
} from 'shared/helpers/vehicles';
import { Link } from 'gatsby';
import ModalCarousel from 'components/Carousel/modalCarousel';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import VehicleCard from 'components/VehicleCard';
import Button from 'components/Button';
import AskQuestionModal from 'features/vehicleDetails/askQuestionModal';
import Collapse from 'components/Collapse';
import ReadMoreLess from 'components/ReadMoreLess';
import Carousel from 'components/Carousel';
import ArrowWithCircle from 'svg/ArrowWithCircle';
import qs from 'qs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faArrowLeft,
  faAlignRight,
} from '@fortawesome/free-solid-svg-icons';
import Share from 'components/Share';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import GatsbyImage from 'components/GatsbyImage/GatsbyImage';
import MailSubmittedModal from 'components/mailSubmittedModal';
import styles from './vehicleDetails.module.scss';
import { REGISTER_TO_BID_PATH, VEHICLE_PATH } from 'utils/pathsConstants';
import HeroSection from 'components/HeroSection';
import { generateFluidImage } from 'utils/image';
import RedirectAction from 'components/CTA/RedirectAction';

const VehicleDetails = ({
  pageData,
  relatedVehicles,
  nextAndPrevVehicles,
  filtersQuery,
  sponsorsData,
  questionModal,
  toggleQuestionModal,
  sendEmail,
  url,
  sendEmailError,
  toggleModalCarousel,
  modalCarouselIsOpen,
  follow,
  toggleFollow,
  defaultNextSlug,
  defaultPrevSlug,
  salesForceId,
  emailData,
  isSendingEmail,
}) => {
  const nextVehicleSlug = nextAndPrevVehicles.data?.NextAndPrevVehicles
    ? nextAndPrevVehicles.data.NextAndPrevVehicles.nextVehicleSlug
    : defaultNextSlug;

  const prevVehicleSlug = nextAndPrevVehicles.data?.NextAndPrevVehicles
    ? nextAndPrevVehicles.data.NextAndPrevVehicles.prevVehicleSlug
    : defaultPrevSlug;

  const nextVehiclePage = nextAndPrevVehicles.data?.NextAndPrevVehicles
    ? nextAndPrevVehicles.data.NextAndPrevVehicles.nextVehiclePage
    : (filtersQuery && filtersQuery.pageNumber) || 0;

  const prevVehiclePage = nextAndPrevVehicles.data?.NextAndPrevVehicles
    ? nextAndPrevVehicles.data.NextAndPrevVehicles.prevVehiclePage
    : (filtersQuery && filtersQuery.pageNumber) || 0;

  const carouselItems = pageData.item.images
    ? pageData.item.images.map(image => {
        const width = image.file?.details?.image?.width;
        const height = image.file?.details?.image?.height;
        return {
          fluid: generateFluidImage(1800, image.file.url, 90, width, height),
        };
      })
    : [];

  const title = getContentfulItemTitle(pageData.item);

  const address = pageData.auction?.location?.address;
  const countryCode = address?.addressCountry;

  const currencyCode = countryCode ? getCurrencyCode(countryCode) : 'USD';

  const placeBidButton = {
    buttonText: 'Place a Bid',
    action: {
      url: pageData.onlineBidLink,
      openInANewTab: true,
    },
    id: pageData.title,
    style: 'Button: Blue Text / No Background',
  };
  return (
    <>
      {(nextAndPrevVehicles.loading || isSendingEmail) && <Spinner />}
      <Layout transparentHeader={true} paddingTop={false}>
        {sendEmailError && <Alert color="danger" msg={sendEmailError} />}
        {emailData?.SendVehicleInquiryEmail?.code === 202 && (
          <MailSubmittedModal />
        )}
        <div className={styles.vehicleDetails}>
          <SEO title={title ?? 'Vehicle Details'} />
          <HeroSection
            image={pageData.item.featuredImage}
            onClick={() => toggleModalCarousel()}
          >
            <div className={styles.bannerText}>
              <Button
                type="app-transparent-white-button"
                handleClick={e => {
                  e.stopPropagation();
                  toggleQuestionModal();
                }}
              >
                Ask a Question
              </Button>
              <div className={styles.smallBtn}>
                <Share />
                {salesForceId && (
                  <Button
                    type={`app-transparent-white-button ${
                      follow ? styles.selected : ''
                    }`}
                    handleClick={e => {
                      e.stopPropagation();
                      _.isFunction(toggleFollow) && toggleFollow();
                    }}
                  >
                    <FontAwesomeIcon icon={faBookmark} />
                    <span>{follow ? 'Unfollow' : 'Follow'}</span>
                  </Button>
                )}
              </div>
            </div>
          </HeroSection>
          <div className={styles.documentation}>
            <div className="col-sm-12">
              <div className={styles.lot}>
                {!!prevVehicleSlug && !nextAndPrevVehicles.loading && (
                  <Link
                    to={`${VEHICLE_PATH(prevVehicleSlug)}/?${
                      filtersQuery
                        ? qs.stringify({
                            ...filtersQuery,
                            pageNumber: prevVehiclePage,
                          })
                        : ''
                    }`}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} />
                  </Link>
                )}
                {pageData.lotNumber &&
                pageData.lotNumber !== '0' &&
                pageData.lotNumber !== 0 ? (
                  <h2>Lot {pageData.lotNumber}</h2>
                ) : (
                  ''
                )}
                {!!nextVehicleSlug && !nextAndPrevVehicles.loading && (
                  <Link
                    to={`${VEHICLE_PATH(nextVehicleSlug)}/?${
                      filtersQuery
                        ? qs.stringify({
                            ...filtersQuery,
                            pageNumber: nextVehiclePage,
                          })
                        : ''
                    }`}
                  >
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Link>
                )}
              </div>
            </div>
            <div className={styles.specificationDetail}>
              <div className="">
                <div className={styles.modal}>
                  <h2 className={styles.textWarmgray}>
                    {pageData.auction
                      ? `${getAuctionYear(
                          pageData.auction.subEvents
                        )} \xa0 | \xa0 ${pageData.auction.name}`
                      : 'Private Sale'}
                  </h2>
                  <ArrowWithCircle />
                </div>
                <h1 className={styles.mainHeading}>{title}</h1>
                <div className={styles.owner}>
                  {pageData.item.note && <p>{pageData.item.note}</p>}
                  {pageData.item.coachwork && (
                    <p>Coachwork by {pageData.item.coachwork}</p>
                  )}
                </div>
                <div className={styles.divider}></div>
                {pageData.salePrice !== undefined &&
                  pageData.salePrice !== null && (
                    <div className={styles.specification}>
                      <h2>
                        {`SOLD ${
                          !pageData.privateSalesPrice
                            ? ` ${getPriceFormatted(
                                pageData.salePrice,
                                currencyCode
                              )}`
                            : ''
                        }`}
                      </h2>
                    </div>
                  )}
                <div className={styles.specification}>
                  {pageData.askingPrice &&
                  (!pageData.salePrice ||
                    pageData.__typename === 'ContentfulPrivateSaleListing') ? (
                    <>
                      <p>Asking Price</p>
                      <span className={styles.amount}>
                        {getPriceFormatted(pageData.askingPrice, currencyCode)}
                      </span>
                    </>
                  ) : pageData.lowEstimate || pageData.highEstimate ? (
                    <>
                      <p>Estimate</p>
                      <h2 className={styles.amount}>
                        <span>
                          {getEstimates(
                            pageData.lowEstimate,
                            pageData.highEstimate,
                            currencyCode
                          )}
                        </span>
                        <span className={styles.regular}>
                          {pageData.hasReservePrice ? '' : `| Without Reserve`}
                        </span>
                      </h2>
                    </>
                  ) : (
                    ''
                  )}
                </div>
                <div className={styles.specificationChassis}>
                  {pageData.item.chassis && (
                    <div className={styles.specification}>
                      <p>Chassis</p>
                      <h2 className={styles.amount}>{pageData.item.chassis}</h2>
                    </div>
                  )}
                  {pageData.item.engine && (
                    <div className={styles.specification}>
                      <p>Engine</p>
                      <h2 className={styles.amount}>{pageData.item.engine}</h2>
                    </div>
                  )}
                </div>
                {pageData.item.documentation && (
                  <Button
                    type="app-transparent-blue-button"
                    handleClick={() => {
                      window.open(pageData.item.documentation.file.url);
                    }}
                  >
                    Documentation
                  </Button>
                )}
              </div>

              <div className={styles.details}>
                {pageData.item.highlights && (
                  <div>
                    <h3>Car Highlights</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: pageData?.item?.highlights?.join('<br />'),
                      }}
                    ></div>
                  </div>
                )}
                {pageData.item.specifications && (
                  <div>
                    <h3>Technical Specs</h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: pageData?.item?.specifications?.join('<br />'),
                      }}
                    ></div>
                  </div>
                )}
                {pageData.sraNote && (
                  <div>
                    <h3 className={styles.sraNote}>
                      <FontAwesomeIcon icon={faAlignRight} />
                      Saleroom Addendum
                    </h3>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: pageData.sraNote.childMarkdownRemark.html,
                      }}
                    ></div>
                  </div>
                )}
                {pageData.onlineBidLink &&
                pageData.auction?.activeAuction === true ? (
                  <div className={styles.onlineBidLink}>
                    <RedirectAction
                      buttonStyle="app-primary-button no-border"
                      callToAction={placeBidButton}
                      extraStyle="linkAsButton"
                      isTextWithOrPrefix={false}
                    />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>

          <div className={styles.carouselContainer}>
            <Carousel
              size="lg"
              fluidImages={carouselItems}
              title={pageData.item.title}
            />
          </div>
          <div className={styles.highlights}>
            <div>
              <div className={styles.sponsor}>
                <div className={styles.bidSection}>
                  <div className="d-flex flex-column">
                    <Button
                      type="app-transparent-blue-button"
                      handleClick={toggleQuestionModal}
                    >
                      Ask a Question
                    </Button>
                    <Button
                      type="app-transparent-blue-button"
                      linkAsButton={true}
                      path={REGISTER_TO_BID_PATH}
                    >
                      Register to Bid
                    </Button>
                  </div>

                  {pageData.item.salesSpecialist && (
                    <div>
                      {pageData.item.salesSpecialist.image && (
                        <div className={styles.salesMen}>
                          <GatsbyImage
                            fixedImage={
                              pageData.item.salesSpecialist.image.fixed
                            }
                            title={pageData.item.salesSpecialist.name}
                          />
                        </div>
                      )}
                      <p>{pageData.item.salesSpecialist.name}</p>
                    </div>
                  )}
                </div>
                {sponsorsData && (
                  <div className={styles.sponsorName}>
                    {sponsorsData.length > 0 && (
                      <p>Exclusive Insurance Sponsor</p>
                    )}
                    <div className={styles.sponsorSection}>
                      {sponsorsData.map((sponsor, key) => (
                        <div className={styles.sponsorImg} key={key}>
                          <GatsbyImage
                            image={sponsor.logo.fluid}
                            title={sponsor.sponsorName}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className={styles.collapseSection}>
                {pageData.item.provenance && (
                  <Collapse
                    title="Provenance"
                    description={pageData.item.provenance}
                  />
                )}
                {pageData.item.raceHighlights && (
                  <Collapse
                    title="Race Highlights"
                    description={pageData.item.raceHighlights ?? ''}
                  />
                )}
                {pageData.item.exhibitionHighlights && (
                  <Collapse
                    title="Exhibition Highlights"
                    description={pageData.item.exhibitionHighlights ?? ''}
                  />
                )}
              </div>
              {pageData.item.description && (
                <ReadMoreLess
                  content={pageData.item.description.childMarkdownRemark.html}
                  isMarkdown={true}
                />
              )}
            </div>
          </div>
          {relatedVehicles.length > 0 && (
            <div className={styles.featuredVehicles}>
              <h5 className="pt18 my-4">RELATED VEHICLES</h5>
              <div className={styles.vehiclesWrapper}>
                {relatedVehicles.map((vehicle, index) => (
                  <VehicleCard
                    key={`vehicleCard_${index}`}
                    fluidImages={vehicle.fluidImages}
                    tag={vehicle.tag}
                    makeModel={vehicle.makeModel}
                    auction={vehicle.auction}
                    price={vehicle.price}
                    lotNumber={vehicle.lotNumber}
                    slug={vehicle.slug}
                    showDotsOnHover={true}
                    salesForceId={vehicle?.salesForceId}
                  />
                ))}
              </div>
            </div>
          )}
        </div>

        <AskQuestionModal
          isOpen={questionModal}
          lotNumber={pageData.lotNumber ?? '-'}
          vehicleName={title}
          auctionVenue={
            pageData.auction ? pageData.auction.name : 'Private Sale'
          }
          toggle={toggleQuestionModal}
          sendEmail={sendEmail}
          url={url}
        />
        <ModalCarousel
          toggle={toggleModalCarousel}
          isOpen={modalCarouselIsOpen}
          fluidImages={carouselItems}
        />
      </Layout>
    </>
  );
};

export default VehicleDetails;
