import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PhoneNumber from 'components/PhoneNumber'
import './mail-submitted-modal.scss';

export default ({isRTB = false, isApproved = false}) => {
  const [modal, toggleModal] = useState(true);
  return (
    <div className="failureModal-modal">
      <Modal
        isOpen={modal}
        toggle={() => toggleModal(!modal)}
        backdrop={'static'}
        centered={true}
        className="failure-exit-modal"
      >
        <div className="modal-content">
          <ModalHeader toggle={() => toggleModal(!modal)}></ModalHeader>
          <ModalBody>
            <div>
              <h3 className="modal-title">SUBMITTED</h3>
            </div>
            <div className="modal-content">
            {
              isRTB ? 
              isApproved ?
                  <p>
                    Thank you for registering to bid with Gooding & Company.
                    Your registration is complete and you will receive a confirmation
                    email with additional details shortly.
                    Should you have any additional questions,
                    please reach out to the Gooding & Company Bidder Registration 
                    staff at <a href="mailto:bids@goodingco.com">bids@goodingco.com</a>
                    or by phone at <a href="tel:310.899.1960">
                      <PhoneNumber number={'310.899.1960'}></PhoneNumber>
                    </a>.
                  </p> 
                :
                  <p>
                    Thank you for completing your bidder registration – you will receive 
                    a confirmation email shortly. We will be in touch within two business 
                    days with any questions or with confirmation of your approved registration
                  </p>
              :
              <p>
                Thank you for contacting us. A Gooding and Company representative will be in touch with you shortly.
              </p>
            }
            </div>
          </ModalBody>
          <ModalFooter className="modal-footer"></ModalFooter>
        </div>
      </Modal>
    </div>
  );
};
