import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import {
  GET_NEXT_VEHICLES,
  SEND_EMAIL,
} from 'services/graphql/queries/vehicles-catalogue';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useAuthenticatedQuery } from 'services/graphql/hooks';
import { GET_FOLLOWED_VEHICLES } from 'services/graphql/queries/vehicles-catalogue';
import VehicleDetails from 'features/vehicleDetails/vehicle-details';
import {
  parseSearchParams,
  mapRelatedLots,
} from 'shared/helpers/vehicles/vehicleDetails';
import { useCheckNotAuthenticatedUsers } from 'shared/helpers/Auth';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
import {
  useFollowedVehiclesUpdate,
  useUnfollowVehiclesUpdate,
} from 'services/graphql/hooks/FollowedVehicles';
import { useRelatedVehicles } from 'services/graphql/hooks/relatedVehicles';
import {
  getPrice,
  PRIVATE_SALE_LISTING,
  getContentfulItemTitle,
  getTag,
} from 'shared/helpers/vehicles';
import { useVenues } from 'shared/queries/venues';

const Vehicles = props => {
  const { pageContext, location } = props;
  const { id, next, prev } = pageContext;
  const pageData =
    props.data.contentfulPrivateSaleListing || props.data.contentfulLot;
  const sponsorsData = props.data.allContentfulSponsor.nodes;
  const url = location.href;
  const searchParams = location.search;
  const [questionModal, setQuestionModal] = useState(false);
  const toggleQuestionModal = () => setQuestionModal(!questionModal);

  const [modalCarouselIsOpen, setModalCarousel] = useState(false);
  const toggleModalCarousel = () => setModalCarousel(!modalCarouselIsOpen);
  const isNotAuthenticated = useCheckNotAuthenticatedUsers();
  const isBrowser = typeof window !== undefined;

  const { data: followedVehiclesData } = useAuthenticatedQuery(
    GET_FOLLOWED_VEHICLES,
    {
      skip: isNotAuthenticated,
    }
  );

  const followedVehicles =
    followedVehiclesData?.followedVehicles?.vehicles
      ?.map(item => item.salesForceId)
      .filter(item => item) ?? [];

  const [follow, setFollow] = useState(false);

  useEffect(() => {
    if (followedVehicles) {
      setFollow(
        followedVehicles.includes(pageData?.item?.salesForceId) ? true : false
      );
    }
  }, [followedVehicles, pageData]);

  const [
    followVehicleRequest,
    { loading: followLoading, error: followError },
  ] = useFollowedVehiclesUpdate(pageData?.item?.salesForceId);

  const [
    unfollowVehicleRequest,
    { loading: unfollowLoading, error: unfollowError },
  ] = useUnfollowVehiclesUpdate(pageData?.item?.salesForceId);

  const followVehicle = () => {
    if (isNotAuthenticated) {
      isNotAuthenticated === 'unverified'
        ? navigate('/auth/unverified')
        : navigate('/auth/login');
    } else {
      const variables = {
        variables: {
          vehicle: { vehicleId: pageData?.item?.salesForceId ?? null },
        },
      };
      !followedVehicles.includes(pageData?.item?.salesForceId)
        ? followVehicleRequest(variables)
        : unfollowVehicleRequest(variables);
    }
  };
  const [searchQuery, setSearchQuery] = useState({});

  const [loadNextAndPrevVehicles, nextAndPrevVehicles] = useLazyQuery(
    GET_NEXT_VEHICLES
  );

  const [
    loadRelatedVehicles,
    { data: relatedVehiclesData, loading: isLoadingRelatedVehicles },
  ] = useLazyQuery(useRelatedVehicles);
  useEffect(() => {
    const searchQuery = parseSearchParams(searchParams);
    setSearchQuery(searchQuery);
    if (isBrowser) {
      loadRelatedVehicles();
      if (!!searchQuery) {
        loadNextAndPrevVehicles({
          variables: {
            ...searchQuery,
            vehicleId: id,
          },
        });
      }
    }
  }, [isBrowser, searchParams, id]);
  const [
    sendEmail,
    { error: emailError, data: emailData, loading: isSendingEmail },
  ] = useMutation(SEND_EMAIL);

  const sendEmailError = emailError
    ? emailError.message
    : emailData &&
      emailData.SendVehicleInquiryEmail &&
      emailData.SendVehicleInquiryEmail.errorMessages
    ? emailData.SendVehicleInquiryEmail.errorMessages.join(',')
    : null;

  const { venues } = useVenues();
  const relatedVehicles = relatedVehiclesData
    ? mapRelatedLots(
        relatedVehiclesData.relatedVehicles.filter(
          lot => lot.auctionStartDate > Date.now()
        ) ?? [],
        venues
      )
    : [];
  const privateSales =
    props.data?.allContentfulPrivateSaleListing?.nodes?.filter(
      node => node.item
    ) ?? [];
  if (!isLoadingRelatedVehicles && relatedVehicles.length === 0) {
    relatedVehicles.push(
      ...privateSales.map(node => {
        return {
          slug: node.slug,
          price: getPrice({ ...node, auctionType: PRIVATE_SALE_LISTING }),
          auction: PRIVATE_SALE_LISTING,
          makeModel: getContentfulItemTitle(node.item),
          tag: getTag({ ...node, auctionType: PRIVATE_SALE_LISTING }),
          fluidImages: node.item.images?.slice(0, 3),
        };
      })
    );
  }

  return (
    <>
      {(followLoading || unfollowLoading) && <Spinner />}
      {followError && <Alert color="danger" msg={followError.message} />}
      {unfollowError && <Alert color="danger" msg={unfollowError.message} />}
      <VehicleDetails
        sponsorsData={sponsorsData}
        relatedVehicles={relatedVehicles.slice(0, 3)}
        nextAndPrevVehicles={nextAndPrevVehicles}
        filtersQuery={searchQuery}
        pageData={pageData}
        questionModal={questionModal}
        toggleQuestionModal={toggleQuestionModal}
        sendEmail={sendEmail}
        url={url}
        sendEmailError={sendEmailError}
        modalCarouselIsOpen={modalCarouselIsOpen}
        toggleModalCarousel={toggleModalCarousel}
        follow={follow}
        toggleFollow={followVehicle}
        salesForceId={pageData?.item?.salesForceId ?? null}
        defaultNextSlug={next}
        defaultPrevSlug={prev}
        emailData={emailData}
        isSendingEmail={isSendingEmail}
      />
    </>
  );
};

export default Vehicles;
export const query = graphql`
  query vehicle($id: String) {
    contentfulLot(contentful_id: { eq: $id }) {
      __typename
      lotNumber
      highEstimate
      hasReservePrice
      salePrice
      askingPrice
      auction {
        name
        contentful_id
        subEvents {
          ... on ContentfulSubEventAuction {
            startDate
          }
        }
        name
        activeAuction
        location {
          address {
            ... on ContentfulAddress {
              id
              addressCountry
            }
            ... on ContentfulAddressFreeform {
              id
              addressCountry
            }
          }
        }
      }
      onlineBidLink
      lowEstimate
      item {
        ...vehicleDetails
        ...automobiliaDetails
      }
      sraNote {
        childMarkdownRemark {
          html
        }
      }
      privateSalesPrice
    }
    contentfulPrivateSaleListing(contentful_id: { eq: $id }) {
      __typename
      salePrice
      askingPrice
      item {
        ...vehicleDetails
        ...automobiliaDetails
      }
    }
    allContentfulSponsor {
      nodes {
        sponsorName
        logo {
          fluid(maxWidth: 154, quality: 90) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    allContentfulPrivateSaleListing {
      nodes {
        contentful_id
        slug
        askingPrice
        salePrice
        item {
          ... on ContentfulAutomobilia {
            contentful_id
            title
            salesForceId
            images {
              title
              fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
          ... on ContentfulVehicle {
            contentful_id
            salesForceId
            make {
              name
            }
            modelYear
            model
            images {
              title
              fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  }

  fragment automobiliaDetails on ContentfulAutomobilia {
    title
    salesForceId
    description {
      childMarkdownRemark {
        html
      }
    }
    featuredImage {
      title
      fluid(maxWidth: 1800, quality: 90) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    images {
      file {
        url
        details {
          image {
            width
            height
          }
        }
      }
      title
    }
  }

  fragment vehicleDetails on ContentfulVehicle {
    title
    highlights
    specifications
    salesForceId
    make {
      name
    }
    modelYear
    model
    exhibitionHighlights
    raceHighlights
    provenance
    note
    featuredImage {
      title
      fluid(maxWidth: 1800, quality: 90) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    images {
      title
      file {
        url
        details {
          image {
            width
            height
          }
        }
      }
    }
    coachwork
    engine
    chassis
    documentation {
      file {
        url
      }
    }
    description {
      childMarkdownRemark {
        html
      }
    }
    salesSpecialist {
      name
      image {
        title
        fixed(width: 154) {
          ...GatsbyContentfulFixed
        }
      }
    }
  }
`;
