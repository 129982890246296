import qs from 'qs';
import {
  getPrice,
  getAlgoliaItemTitle,
  mapVenuesToCurrencies,
  getAuction,
} from 'shared/helpers/vehicles';
import { generateFluidImage } from 'utils/image';

export const parseSearchParams = searchParams => {
  const searchQuery = searchParams ? qs.parse(searchParams.substring(1)) : null;

  if (searchQuery) {
    if (!('filtersInput' in searchQuery)) {
      searchQuery['filtersInput'] = {
        make: [],
        year: undefined,
        estimate: undefined,
        auctionType: [],
        itemType: [],
        venue: [],
        auctionYear: [],
        hasReservePrice: undefined,
        onlineBiddingAvailable: undefined,
      };
    }
    if (typeof searchQuery['pageNumber'] === 'string')
      searchQuery['pageNumber'] = parseInt(searchQuery['pageNumber']);

    if (
      searchQuery.filtersInput.hasReservePrice &&
      typeof searchQuery.filtersInput['hasReservePrice'] === 'string'
    )
      searchQuery.filtersInput['hasReservePrice'] =
        searchQuery.filtersInput['hasReservePrice'] === 'true';

    if (
      searchQuery.filtersInput.onlineBiddingAvailable &&
      typeof searchQuery.filtersInput.onlineBiddingAvailable === 'string'
    )
      searchQuery.filtersInput.onlineBiddingAvailable =
        searchQuery.filtersInput.onlineBiddingAvailable === 'true';
  }
  return searchQuery;
};

export const mapRelatedLots = (lots, venues) => {
  const currencies = mapVenuesToCurrencies(venues);
  return lots.map(lot => {
    return {
      tag: 'Upcoming',
      makeModel: getAlgoliaItemTitle(lot),
      fluidImages: !!lot.images.length
        ? lot.images
            .map(image => {
              const width = image.file?.details?.image?.width;
              const height = image.file?.details?.image?.height;
              return {
                fluid: generateFluidImage(
                  332,
                  image.file.url,
                  90,
                  width,
                  height
                ),
              };
            })
            .slice(0, 3)
        : null,
      auction: getAuction(lot),
      lotNumber: lot.lotNumber,
      slug: lot.slug,
      salesForceId: lot.salesForceId,
      price: getPrice(lot, currencies[lot.venueId]),
    };
  });
};
