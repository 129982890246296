export function buildEmailTemplate(values, link) {
  const body = `
  <div class="gs">
  <div>
  <div id=":1t" class="ii gt">
  <div id=":1u" class="a3s aXjCH ">
  <div dir="ltr">
  <div class="gmail_quote">
  <div dir="ltr">
  <div class="gmail_quote">
  <div>
  <div class="gmail_quote">
  <div class="gmail_attr" dir="ltr">&nbsp;</div>
  <br />
  <div>
  <div dir=""><center>
  <table id="m_-6352185674597216352m_7211014878592900210m_-6427596666040422757m_490262243150175087backgroundTable" style="width: 100%; height: 100%;" border="0" cellspacing="0" cellpadding="0">
  <tbody>
  <tr>
  <td align="center" valign="top">
  <table id="m_-6352185674597216352m_7211014878592900210m_-6427596666040422757m_490262243150175087templateContainer" style="width: 600px;" border="0" cellspacing="0" cellpadding="0">
  <tbody>
  <tr>
  <td id="m_-6352185674597216352m_7211014878592900210m_-6427596666040422757m_490262243150175087cellContainer" align="center" valign="top">
  <div id="m_-6352185674597216352m_7211014878592900210m_-6427596666040422757m_490262243150175087divTemplateHeaderImage">
  <table id="m_-6352185674597216352m_7211014878592900210m_-6427596666040422757m_490262243150175087templateHeaderImage" style="width: 100%;" border="0" cellspacing="0" cellpadding="0">
  <tbody>
  <tr>
  <td align="left" valign="top">
  <p><img class="CToWUd" src="https://ci4.googleusercontent.com/proxy/Xwvoi7DHrAZzXSgrSlUEP2CxVUqXYUbCb6AyrId_80zQld2EBosd3_O7-9HvA1Dp7gfuMGxdq_VQo_V0Nzs0EDmihByHdGd-DZnqkONC3IntjBRo5aCn566rDVOxXRP3ywWd5B6aRw=s0-d-e1-ft#http://d2f66jdfnuocai.cloudfront.net/wp-content/themes/kissponsive/images/logo/0.png" alt="Gooding &amp; Company" /></p>
  </td>
  </tr>
  </tbody>
  </table>
  </div>
  <div id="m_-6352185674597216352m_7211014878592900210m_-6427596666040422757m_490262243150175087divTemplateHeader">
  <table id="m_-6352185674597216352m_7211014878592900210m_-6427596666040422757m_490262243150175087templateHeader" style="width: 100%;" border="0" cellspacing="0" cellpadding="0">
  <tbody>
  <tr>
  <td align="left">
  <div>
  <h1>Email a Specialist - ${values.vehicleName} (<a href="${
    link.split('?')[0]
  }" target="_blank" >${link.split('?')[0]}</a>)</h1>
  </div>
  </td>
  </tr>
  </tbody>
  </table>
  </div>
  <div id="m_-6352185674597216352m_7211014878592900210m_-6427596666040422757m_490262243150175087divTemplateBody">
  <table id="m_-6352185674597216352m_7211014878592900210m_-6427596666040422757m_490262243150175087templateBody" style="width: 100%;" border="0" cellspacing="0" cellpadding="0">
  <tbody>
  <tr>
  <td align="left" valign="top">
  <div>
  <table style="width: 552px;" border="0" cellspacing="0" cellpadding="0">
  <tbody>
  <tr>
  <td valign="top">
  <div>
  <table style="width: 99%;" border="0" cellspacing="0" cellpadding="1" bgcolor="#EAEAEA">
  <tbody>
  <tr>
  <td>
  <table style="width: 100%;" border="0" cellspacing="0" cellpadding="5" bgcolor="#FFFFFF">
  <tbody>
  <tr bgcolor="#EAF2FA">
  <td colspan="2"><span><strong>Name</strong></span></td>
  </tr>
  <tr bgcolor="#FFFFFF">
  <td width="20">&nbsp;</td>
  <td><span>${values.name}</span></td>
  </tr>
  <tr bgcolor="#EAF2FA">
  <td colspan="2"><span><strong>Email</strong></span></td>
  </tr>
  <tr bgcolor="#FFFFFF">
  <td width="20">&nbsp;</td>
  <td><span><a href="mailto:${values.email}" target="_blank">${
    values.email
  }</a></span></td>
  </tr>
  <tr bgcolor="#EAF2FA">
  <td colspan="2"><span><strong>Phone</strong></span></td>
  </tr>
  <tr bgcolor="#FFFFFF">
  <td width="20">&nbsp;</td>
  <td><span>${values.phoneNumber}</span></td>
  </tr>
  <tr bgcolor="#EAF2FA">
  <td colspan="2"><span><strong>Message</strong></span></td>
  </tr>
  <tr bgcolor="#FFFFFF">
  <td width="20">&nbsp;</td>
  <td><span>${values.message}</span></td>
  </tr>
  <tr bgcolor="#EAF2FA">
  <td colspan="2"><span><strong>Page</strong></span></td>
  </tr>
  <tr bgcolor="#FFFFFF">
  <td width="20">&nbsp;</td>
  <td><span> <strong>Vehicle</strong>: ${values.vehicleName} (<a href="${
    link.split('?')[0]
  }" rel="nofollow" target="_blank" >${link.split('?')[0]}</a>)</span></td>
  </tr>
  <tr bgcolor="#FFFFFF">
  <td width="20">&nbsp;</td>
  <td><span> <strong>Lot Number</strong>: ${values.lotNumber} </span></td>
  </tr>
  <tr bgcolor="#FFFFFF">
  <td width="20">&nbsp;</td>
  <td><span> <strong>Auction</strong>: ${values.auctionVenue} </span></td>
  </tr>
  </tbody>
  </table>
  </td>
  </tr>
  </tbody>
  </table>
  </div>
  </td>
  </tr>
  </tbody>
  </table>
  </div>
  </td>
  </tr>
  </tbody>
  </table>
  </div>
  <div id="m_-6352185674597216352m_7211014878592900210m_-6427596666040422757m_490262243150175087divTemplateFooter">
  <table id="m_-6352185674597216352m_7211014878592900210m_-6427596666040422757m_490262243150175087templateFooter" style="width: 100%;" border="0" cellspacing="0" cellpadding="0">
  <tbody>
  <tr>
  <td id="m_-6352185674597216352m_7211014878592900210m_-6427596666040422757m_490262243150175087credit" align="center" valign="middle">
  <div>
  <div>
  <p>&nbsp;</p>
  <p><strong>Gooding &amp; Company</strong></p>
  <p><a href="https://www.google.com/maps/search/1517+20th+Street+%0D%0ASanta+Monica,+CA+90404?entry=gmail&amp;source=g" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.google.com/maps/search/1517%2B20th%2BStreet%2B%250D%250ASanta%2BMonica,%2BCA%2B90404?entry%3Dgmail%26source%3Dg&amp;source=gmail&amp;ust=1597691603148000&amp;usg=AFQjCNGb-k666kTXl-QcQsseejcD_YkX0g">1517 20th Street</a><br /><a href="https://www.google.com/maps/search/1517+20th+Street+%0D%0ASanta+Monica,+CA+90404?entry=gmail&amp;source=g" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://www.google.com/maps/search/1517%2B20th%2BStreet%2B%250D%250ASanta%2BMonica,%2BCA%2B90404?entry%3Dgmail%26source%3Dg&amp;source=gmail&amp;ust=1597691603148000&amp;usg=AFQjCNGb-k666kTXl-QcQsseejcD_YkX0g">Santa Monica, CA 90404</a></p>
  <p><a href="mailto:info@goodingco.com" target="_blank">info@goodingco.com</a></p>
  <p>Tel: 310.899.1960<a href="tel:3108991960" target="_blank"><br /></a>Fax: 310.526.6594</p>
  </div>
  </div>
  </td>
  </tr>
  </tbody>
  </table>
  </div>
  </td>
  </tr>
  </tbody>
  </table>
  </td>
  </tr>
  </tbody>
  </table>
  </center></div>
  </div>
  </div>
  </div>
  <div class="yj6qo">&nbsp;</div>
  <div class="adL">&nbsp;</div>
  <div class="adL" dir="ltr" data-smartmail="gmail_signature">&nbsp;</div>
  <div class="adL">&nbsp;</div>
  </div>
  </div>
  <div class="adL">&nbsp;</div>
  </div>
  </div>
  <div class="adL">&nbsp;</div>
  </div>
  </div>
  <div class="hi">&nbsp;</div>
  </div>
  </div>
  `;

  return body;
}
