import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, CardBody, Card } from 'reactstrap';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import styles from './collapse.module.scss';

const AppCollapse = ({ title, description }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <div className={styles.appCollapse}>
      <Button type={styles.collapseBtn} handleClick={toggle}>
        {title}
        <FontAwesomeIcon
          className={styles.plusIcon}
          icon={isOpen ? faMinus : faPlus}
        />
      </Button>
      <Collapse isOpen={isOpen}>
        <Card className={styles.card}>
          <CardBody className={styles.cardBody}>
            <p>{description}</p>
          </CardBody>
        </Card>
      </Collapse>
    </div>
  );
};

AppCollapse.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
};

export default AppCollapse;
