import React, { useRef } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Form, Formik } from 'formik';
import Button from 'components/Button';
import {
  INITIAL_VALUES,
  VALIDATION_SCHEMA,
} from 'components/utils/askAQuestion';
import PhoneNumber from 'components/form-elements/PhoneNumber';
import TextInput from 'components/form-elements/TextInput';
import TextArea from 'components/form-elements/TextArea';
import CloseIcon from 'svg/CloseIcon';
import styles from './askQuestionModal.module.scss';
import { buildEmailTemplate } from 'features/vehicleDetails/askQuestionModal/emailTemplate';

const AskQuestionModal = ({
  isOpen,
  toggle,
  lotNumber,
  auctionVenue,
  vehicleName,
  sendEmail,
  url,
}) => {
  const formRef = useRef(null);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={styles.askQuestionVehicleDetails}
    >
      <div className={styles.header}>
        <Button handleClick={toggle}>
          <CloseIcon color="silver" />
        </Button>
        <h3>Ask a question</h3>
      </div>
      <ModalBody className={styles.body}>
        <Formik
          initialValues={{
            ...INITIAL_VALUES,
            lotNumber,
            auctionVenue,
            vehicleName,
          }}
          validationSchema={VALIDATION_SCHEMA}
          innerRef={formRef}
          onSubmit={async values => {
            const subject = `[Inquiry] Email a Specialist - ${values.vehicleName}`;
            const body = buildEmailTemplate(values, url);
            toggle();
            await sendEmail({
              variables: {
                fromName: values.name,
                subject,
                body,
              },
            });
          }}
        >
          {({ isValid, dirty, setFieldValue, isSubmitting }) => {
            return (
              <Form>
                <div className={styles.fieldGroup}>
                  <div className={styles.lotNumberSec}>
                    <div className={styles.fieldSec}>
                      <TextInput
                        name="lotNumber"
                        placeholder="Lot Number"
                        showErrorMessages={false}
                        disabled={true}
                      />
                    </div>
                    <div className={styles.fieldSec}>
                      <TextInput
                        className={styles.field}
                        name="auctionVenue"
                        placeholder="Auction Venue"
                        showErrorMessages={false}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className={styles.field}>
                    <TextInput
                      className={styles.field}
                      name="vehicleName"
                      placeholder="Vehicle Name"
                      showErrorMessages={false}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className={styles.fieldGroup}>
                  <div className={styles.field}>
                    <TextInput
                      name="name"
                      placeholder="Name *"
                      showErrorMessages={false}
                    />
                  </div>

                  <div className={styles.field}>
                    <TextInput
                      name="email"
                      placeholder="Email*"
                      showErrorMessages={false}
                    />
                  </div>
                </div>
                <div className={styles.fieldGroup}>
                  <div className={styles.field}>
                    <TextInput
                      className={styles.field}
                      name="confirmEmail"
                      placeholder="Confirm Email*"
                      showErrorMessages={false}
                    />
                  </div>
                  <div className={styles.field}>
                    <PhoneNumber
                      name="phoneNumber"
                      placeholder="Phone Number*"
                      label="&#42;"
                      showErrorMessages={false}
                    />
                  </div>
                </div>
                <div className={styles.fieldGroup}>
                  <div className={styles.textField}>
                    <TextArea
                      className={styles.field}
                      name="message"
                      placeholder="Message of Inquiry*"
                      showErrorMessages={false}
                    />
                  </div>
                </div>
                <div className={styles.footer}>
                  <Button
                    type="app-primary-button"
                    handleClick={() => {
                      formRef.current.submitForm();
                    }}
                    disabled={!(isValid && dirty) || isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default AskQuestionModal;
